<template>
  <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }"
    class="align-self-start col-12 pa-0">
    <v-form @submit.prevent="passes(login)">
      <v-container>
          <ValidationProvider name="Nombre" rules="required" v-slot="{ errors, valid }">
            <v-text-field
              filled
              v-model="item.nombre"
              append-icon=" "
              label="Nombre"
              required
              readonly
              autofocus
              :error-messages="errors"
              :success="valid"
            ></v-text-field>
          </ValidationProvider>

          <ValidationProvider name="Email" rules="required|email" v-slot="{ errors, valid }">
            <v-text-field
              filled
              v-model="item.email"
              append-icon=" "
              type="email"
              label="Email"
              required
              :error-messages="errors"
              :success="valid"
            ></v-text-field>
          </ValidationProvider>

          <ValidationProvider name="Clave Actual" rules="required" v-slot="{ errors, valid }">
            <v-text-field
              filled
              v-model="item.claveActual"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-1"
              label="Clave Actual"
              counter
              @click:append="showPassword = !showPassword"
              :error-messages="errors"
              :success="valid"
            ></v-text-field>
          </ValidationProvider>

          <v-row>
            <v-col cols="12" sm="6">
              <ValidationProvider name="Clave Nueva" rules="confirmed:clave_confirmation"
                v-slot="{ errors, valid }">
            <v-text-field
              filled
              v-model="item.claveNueva"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-1"
              label="Clave Nueva"
              counter
              @click:append="showPassword = !showPassword"
              :error-messages="errors"
              :success="valid"
            ></v-text-field>
              </ValidationProvider>
        </v-col>

            <v-col cols="12" sm="6">
              <ValidationProvider name="Confirmar Clave Nueva" v-slot="{ errors, valid }"
                vid="clave_confirmation">
            <v-text-field
              filled
              v-model="ConfirmacionClaveNueva"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-1"
              label="Confirmar Clave Nueva"
              counter
              @click:append="showPassword = !showPassword"
              :error-messages="errors"
              :success="valid"
              ></v-text-field>
              </ValidationProvider>
        </v-col>
      </v-row>

          <div align-self-center class="mt-4">
            <v-btn type="submit" @click.prevent="passes(save)" color="primary"
                dark :loading="saving" :disabled="saving">Guardar</v-btn>
          </div>
      </v-container>
    </v-form>
  </ValidationObserver>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    loading: false,
    saving: false,
    showPassword: false,
    item: {
      nombre: '',
      email: '',
      claveActual: '',
      claveNueva: '',
    },
    ConfirmacionClaveNueva: '',
  }),

  methods: {
    get: function () {
    this.loading = true

    this.$soapClient.call('obtenerPerfil')
    .then((response) => {
      let resultado = response.data.resultado

      this.item.nombre = resultado.nombre
      this.item.email = resultado.email
    })
    .catch(() => {
      this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
    })
    .then(() => {
      this.loading = false
    })
    },
    save: function () {
    this.saving = true

    this.$soapClient.call('editarPerfil', this.item)
    .then(() => {
      this.item.claveActual = ''
      this.item.claveNueva = ''
      this.ConfirmacionClaveNueva = ''

      this.$refs.obs.reset()

      this.$eventHub.$emit('snackbar-message', 'Los datos fueron guardados con éxito', 'success')
    })
    .catch((error) => {
      if (!!error.code && (error.code === "400")) {
            this.$eventHub.$emit('snackbar-message', error.message, 'error')
          }
    })
    .then(() => {
      this.saving = false
    })
    },
  },

  created: function () {
    this.get()
  }
}

</script>
